<template>
  <auth-layout>
    <validation-observer ref="loginForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="login">
        <app-input v-model="email" type="email" name="E-Posta" label="E-Posta:" placeholder="E-Posta" rules="required|email" />
        <app-input v-model="password" type="password" name="Şifre" label="Şifre:" placeholder="Şifreniz..." rules="required" />
        <b-button variant="gradient-primary" type="submit" block> Giriş Yap </b-button>
      </b-form>
    </validation-observer>
  </auth-layout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import AuthLayout from "./AuthLayout.vue";

export default {
  components: {
    VuexyLogo,
    AuthLayout,
    ValidationObserver,
  },
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    login() {
      this.$validate(this.$refs.loginForm, () => {
        this.$axios
          .post(
            "/administrators/login",
            {
              email: this.email,
              password: this.password,
            },
            { loading: "table" }
          )
          .then((response) => {
            switch (response.status) {
              case 202: {
                const user = response.data;
                sessionStorage.setItem("token", user.token);
                this.$axios.defaults.headers.common.Authorization = user.token;
                this.$store.commit("setUser", user);
                delete user.token;
                sessionStorage.setItem("userData", JSON.stringify(user));
                this.$ability.update(user?.ability);
                this.$router.push({
                  name: !user.first_login ? "first-login" : "dashboard",
                });
                break;
              }
              case 200: {
                this.$store.commit("setSmsCheck", response.data);
                this.$router.push({ name: "sms-check" });
                break;
              }
              default:
                break;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emitter.$emit("Notification", {
              title: "Giriş Yapılamadı",
              variant: "danger",
              message: error?.data?.message,
            });
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
