<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <app-overlay>
        <b-card class="d-flex mb-0 border">
          <div class="d-flex align-items-center flex-column">
            <vuexy-logo />
            <span class="brand-text text-primary">Yönetim Paneli</span>
          </div>
          <hr />
          <slot />
        </b-card>
      </app-overlay>
    </div>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";

export default {
  components: {
    VuexyLogo,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
